import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import {
    imagesSVFirst,
    imagesSVSecond,
    imagesSVThird,
    imagesSVFourth,
    imagesSVFive,
    imagesSVSix,
    imagesSVSeven,
    imagesSVEight,
    imagesSVNine,
    imagesSVBFirst,
    imagesSVBSecond,
    imagesSVBThird,
    imagesSVBFourth,
    imagesSVBFive,
    imagesSVBSix,
    imagesSVBSeven,
    imagesSVBEight,
    imagesSVBNine,
} from '../assets/images';

const getImageSrc = (id) => {
  switch (id) {
    case 'mymodal1':
      return imagesSVFirst;
    case 'mymodal2':
      return imagesSVSecond;
    case 'mymodal3':
      return imagesSVThird;
    case 'mymodal4':
      return imagesSVFourth;
    case 'mymodal5':
      return imagesSVFive;
    case 'mymodal6':
      return imagesSVSix;
    case 'mymodal7':
      return imagesSVSeven;
    case 'mymodal8':
      return imagesSVEight;
    case 'mymodal9':
      return imagesSVNine;
    default:
      return imagesSVFirst;
  }
};

const getBodyImageSrc = (id) => {
  switch (id) {
    case 'mymodal1':
      return imagesSVBFirst;
    case 'mymodal2':
      return imagesSVBSecond;
    case 'mymodal3':
      return imagesSVBThird;
    case 'mymodal4':
      return imagesSVBFourth;
    case 'mymodal5':
      return imagesSVBFive;
    case 'mymodal6':
      return imagesSVBSix;
    case 'mymodal7':
      return imagesSVBSeven;
    case 'mymodal8':
      return imagesSVBEight;
    case 'mymodal9':
      return imagesSVBNine;
    default:
      return imagesSVBFirst;
  }
};

const getTitle = (id) => {
  switch (id) {
    case 'mymodal1':
      return 'Author Website Design Development';
    case 'mymodal2':
      return 'Social Media Marketing';
    case 'mymodal3':
      return 'Search Engine Optimization ';
    case 'mymodal4':
      return 'Video Review';
    case 'mymodal5':
      return 'Online Independent Reviews';
    case 'mymodal6':
      return 'Book Trailer';
    case 'mymodal7':
      return 'Editorial Critiques';
    case 'mymodal8':
      return 'Blogs for Book Promotion';
    case 'mymodal9':
      return 'Press Releases';
    default:
      return 'Author Website Design & Development';
  }
};

const getContent = (id) => {
  switch (id) {
    case 'mymodal1':
      return 'A website is an author’s essential platform for promoting their brand, genre, and books to a wider online audience, reassuring readers of their professionalism and expanding their reach.';
    case 'mymodal2':
      return "In this digital age, an author's social media presence is crucial for building their brand, boosting website traffic, selling more books, and engaging a ready fan base, and we can help manage and enhance your social media with creative content on Facebook, Twitter, and Google Plus.";
    case 'mymodal3':
      return "One of the primary ways to drive traffic to websites for authors is through the search engines (like Google). That’s why it’s important that any new site be properly optimized to appear for the keywords people search for the most. As part of this package, we identify keywords that provide the greatest chance of success for the client. This recommended keyword list is based on a combination of client input and extensive research into search traffic and levels of competition.";
    case 'mymodal4':
      return "Favorable media exposure can boost your credibility as an author, help get your book stocked in stores, and ultimately improve your sales. But breaking through the noise can be a challenge without the right support. We’ll develop a custom plan tailored to your goals - a solid and strategic foundation for you to build on. A professional video review will enhance your marketing materials and add a competitive edge to your book’s presence.";
    case 'mymodal5':
      return "As a newly published author, getting your book reviewed by an unbiased professional is one of the key hurdles that you need to overcome. Customer reviews on Amazon and elsewhere are key to building credibility for your book and in creating buzz. Anyone surfing for the book also gets to see what other consumers and readers have to say about the book and helps the buyer’s decision making.";
    case 'mymodal6':
      return "A book trailer is all about making the readers relate to the story and suggesting various emotions that will allow the reader to better understand what the book is all about. Book trailers are a new concept of marketing the author’s book, especially when they are designed in a professional way. The book trailer’s ability to bring out the essence of the book in a short and entertaining manner can help boost the author’s sales.";
    case 'mymodal7':
      return "As an Author with a completed manuscript, would you like an unbiased and professional opinion of your manuscript? Most authors are always looking for someone else apart from friends and family (who do sometimes offer a good opinion) to read their manuscript and give a critique. This allows you to gauge how the market will respond to your book by getting literary experts to critique it.";
    case 'mymodal8':
      return "Bloggers are a really important part of the book world today. The biggest fans of your book genre regularly subscribe to or visit book review blogs dedicated to the genre. The reach of the most influential book review blogs in your genre is most likely in the tens of thousands. These can be brilliant for getting nice reviews and spreading news about a book at a grassroots level.";
    case 'mymodal9':
      return "A press release announcing the launch of the book will be distributed to hundreds of news agencies, media houses and journalists. The release will include the cover image of the book along with up to three links to e-commerce sites for the reader to purchase if interested. A powerful press release can take the author communication to new channels & reach new readers. ";
    default:
      return 'A website is an author’s shop front. From here, you’re promoting your brand, your genre, your book and you to the reading, book buying public. Your website is a crucial platform, regardless of how simple or complex you make it. Many of your readers are searching for books and buying them online, so your website needs to deliver. A high-quality site will reassure them that you’re a professional, while giving you the freedom to seek out an even larger readership.';
  }
};

export const ServicesModal = ({ open, onCloseModal, id }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  return (
    <Modal id={id} show={open} onHide={onCloseModal} aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-dialog section-modal-dialog">
            <div className="modal-content secion-modal">
                <div className="modal-header">
                    <button type="button" className="close" onClick={onCloseModal}>×</button>
                    <i><img height="auto" width="auto" src={getImageSrc(id)} title="img" alt="image" /></i>
                    <h3>{getTitle(id)}</h3>
                </div>
                <div className="modal-body">
                    <img height="auto" width="auto" src={getBodyImageSrc(id)} title="img" alt="image" />
                    <p>{getContent(id)}</p>
                </div>
            </div>
        </div>
    </Modal>
  );
};
