import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FictionSlider from './components/FictionSlider';
import {
  logo,
  chooseIcon1,
  banner,
  chooseIcon,
  contactFormAfter,
  contactFormBefore,
  ctaFirst,
  ctaSecond,
  discountAfter,
  discountBefore,
  favIcon,
  footerBg,
  footerLogo,
  imagesBookRights,
  imagesLeafLeft,
  newsFirst,
  newsSecond,
  newsThird,
  newsFourth,
  newsFive,
  newsSixth,
  newsSeventh,
  introMainImg,
  imagesSVFirst,
  imagesSVSecond,
  imagesSVThird,
  imagesSVFourth,
  imagesSVFive,
  imagesSVSix,
  imagesSVSeven,
  imagesSVEight,
  imagesSVNine,
  trust,
} from './assets/images';
import {
  ADDRESS,
  EMAIL,
  EMAIL_HREF,
  PHONE,
  PHONE_HREF,
  WEBSITE_LOGO,
  WEBSITE_NAME,
} from './config';
import { ModalSection } from './layout/modalSection';
import { ServicesModal } from './components/ServicesModal';
import NonFictionSlider from './components/NonFictionSlider';
import BiographySlider from './components/BiographySlider';
import InformativeSlider from './components/InformativeSlider';
import AutoBiographySlider from './components/AutoBiographySlider';
import MultiStepForm from './components/MultiStepForm';

function App() {
  useEffect(() => {
    AOS.init({});
  }, []);

  var bookSlider = {
    dots: false,
    prevArrow: false,
    nextArrow: false,
    infinite: true,
    autoplay: true,
    loop: true,
    speed: 200,
    slidesToShow: 5,
    pauseOnHover: false,
    centerMode: true,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "authorspublishinglab.com"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=562174';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://authorspublishinglab.com/thank-you?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const [modalState, setModalState] = useState({
    modalSection: false,
    servicesModal: false,
  });
  const [isModalSectionOpen, setIsModalSectionOpen] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);

  const openModalSection = () => {
    setIsModalSectionOpen(true);
  };

  const closeModalSection = () => {
    setIsModalSectionOpen(false);
  };

  //   const openServicesModal = () => {
  //     setIsServicesModalOpen(true);
  //   };

  const openServicesModal = (id) => {
    setModalState({ ...modalState, servicesModal: id });
  };

  const closeServicesModal = () => {
    setModalState({ ...modalState, servicesModal: false });
  };

  useEffect(() => {
    // Define the function to toggle the chat
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    // Attach event listeners when the component mounts
    const chatButton = document.querySelector('.chat'); // Replace '.chat' with the appropriate button selector
    if (chatButton) {
      chatButton.addEventListener('click', toggleChat);
    }

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      if (chatButton) {
        chatButton.removeEventListener('click', toggleChat);
      }
    };
  }, []);

  return (
    <div className="App">
      <header>
        <div class="container-xl">
          <div class="row justify-content-between no-gutters align-items-center">
            <div class="col-5  col-xl-3 col-lg-3 ">
              <div
                class="logo aos-init aos-animate"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                <img
                  height="auto"
                  width="auto"
                  src={logo}
                  title="img"
                  alt="img"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-7 col-xl-5 col-lg-5 ">
              <div
                class="tal_us aos-init aos-animate justify-content-between align-items-center"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                <ul class="talk_list pl-0 mb-0">
                  <li>
                    <a href="javascript:;" class="chat">
                      <i class="sprite sprite-chat_icon"></i> TALK TO US
                    </a>
                  </li>
                  <li>
                    <a href={PHONE_HREF}>
                      <i class="sprite sprite-tell"></i>
                      {PHONE}
                    </a>
                  </li>
                </ul>
                <a
                  href="javascript:;"
                  class="gets_quotes"
                  onClick={openModalSection}
                >
                  Get A FREE QUOTE
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section class="banner">
        <div class="container-xl">
          <div class="banner-txt">
            <h1>
              Book <span>Marketing</span> & <span>Promotion</span> Services
            </h1>
            <p>Are you struggling to sell more books?</p>
            <p>
              {WEBSITE_NAME} is a book marketing company that specializes in
              turning authors into renowned success stories with the help of
              innovative, personalized and targeted book promotion services
            </p>
          </div>
          <MultiStepForm />
        </div>
      </section>

      <main>
        <section class="news-icons-area">
          <div class="container-fluid">
            <div class="row news-icons-slider">
              <div class="col text-center">
                <img
                  height="auto"
                  width="auto"
                  src={newsFirst}
                  class="img-fluid"
                />
              </div>
              <div class="col text-center">
                <img
                  height="auto"
                  width="auto"
                  src={newsSecond}
                  class="img-fluid"
                />
              </div>
              <div class="col text-center">
                <img
                  height="auto"
                  width="auto"
                  src={newsThird}
                  class="img-fluid"
                />
              </div>
              <div class="col text-center">
                <img
                  height="auto"
                  width="auto"
                  src={newsFourth}
                  class="img-fluid"
                />
              </div>
              <div class="col text-center">
                <img
                  height="auto"
                  width="auto"
                  src={newsFive}
                  class="img-fluid"
                />
              </div>
              <div class="col text-center">
                <img
                  height="auto"
                  width="auto"
                  src={newsSixth}
                  class="img-fluid"
                />
              </div>
              <div class="col text-center">
                <img
                  height="auto"
                  width="auto"
                  src={newsSeventh}
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section class="intro-area sec-padd">
          <div class="container-xl">
            <div class="intro-hdg">
              <p>{WEBSITE_NAME} Gives You The Clarity To Navigate The</p>
              <h2>Complex Publishing World</h2>
            </div>
            <div class="intro-main-wrap">
              <div class="row">
                <div class="col-lg-6">
                  <p>
                    We work with committed self-published and traditionally
                    published authors who understand today's market requires a
                    smart and strategic book promotion plan.
                  </p>
                  <p>
                    A collaboration with us is an investment in your long term
                    success. We can design and execute a book marketing plan
                    independently or in cooperation with your marketing and
                    publicity teams to create a cohesive and powerful book
                    launch.
                  </p>
                </div>
                <div class="col-lg-5 text-center">
                  <img
                    height="auto"
                    width="auto"
                    src={introMainImg}
                    title="img"
                    alt="intro-main-image"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="cta-1">
          <div class="container-xl">
            <div class="cta-1-inner">
              <h2>Book Marketing is a Cost-Effective</h2>
              <h4>& Practical method to drive your book’s sales.</h4>
              <p>
                That’s where we come in. Our trusted book marketing experts
                understand authors and publishers.
              </p>
              <a href="javascript:;" class="btn-1 chat">
                I HAVE A BOOK, LETS CHAT
              </a>
            </div>
          </div>
        </section>

        <section class="bookservices">
          <div class="container">
            <h2>Our Premium Book Marketing Services</h2>
            <div class="row marketing-slider">
              <div class="col-md-4 col-blue">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVFirst}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Author Website <br />
                    Design &amp; Development
                  </h3>
                  <span class="price">$1699</span>
                  <strong>$1999</strong>
                  <p>
                    A website is an author’s shop front. From here, you’re
                    promoting your brand, your genre, your book and you to the
                    reading, book buying public. Your website is a crucial
                    platform, regardless of how simple or complex you make it.
                    Many of your readers are searching for books and buying them
                    online, so your website needs to deliver. A high-quality
                    site will reassure them that you’re a professional, while
                    giving you the freedom to seek out an even larger
                    readership.{' '}
                  </p>
                  <a
                    href="javascript:;"
                    className="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal1')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-green">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVSecond}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Social <br />
                    Media Marketing
                  </h3>
                  <p>
                    In this digital age, your social media presence as an author
                    is a building block to your brand. Social media can help you
                    boost website traffic, sell more books, build brand
                    recognition, and get you a ready fan base for your next
                    book. Allow us to manage your social media, and we will help
                    enhance the fan base as well as keep your followers engaged
                    with relevant and creative content across the platforms of
                    Facebook, Twitter &amp; Google Plus.{' '}
                  </p>
                  <strong>$2499</strong>
                  <span class="price">$1999</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal2')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-orange">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVThird}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Search Engine <br />
                    Optimization{' '}
                  </h3>
                  <p>
                    One of the primary ways to drive traffic to websites for
                    authors is through the search engines (like Google). That’s
                    why it’s important that any new site be properly optimized
                    to appear for the keywords people search for the most. As
                    part of this package, we identify keywords that provide the
                    greatest chance of success for the client. This recommended
                    keyword list is based on a combination of client input and
                    extensive research into search traffic and levels of
                    competition.
                  </p>
                  <strong>$4999</strong>
                  <span class="price">$3999</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal3')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
            </div>
            <div class="row marketing-slider">
              <div class="col-md-4 col-lblue">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVFourth}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Video <br />
                    Review
                  </h3>
                  <p>
                    Favorable media exposure can boost your credibility as an
                    author, help get your book stocked in stores, and ultimately
                    improve your sales. But breaking through the noise can be a
                    challenge without the right support. We’ll develop a custom
                    plan tailored to your goals - a solid and strategic
                    foundation for you to build on. A professional video review
                    will enhance your marketing materials and add a competitive
                    edge to your book’s presence.{' '}
                  </p>
                  <strong>$1249</strong>
                  <span class="price">$999</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal4')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-lorange">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVFive}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Online Independent <br />
                    Reviews
                  </h3>
                  <p>
                    As a newly published author, getting your book reviewed by
                    an unbiased professional is one of the key hurdles that you
                    need to overcome. Customer reviews on Amazon and elsewhere
                    are key to building credibility for your book and in
                    creating buzz. Anyone surfing for the book also gets to see
                    what other consumers and readers have to say about the book
                    and helps the buyer’s decision making.{' '}
                  </p>
                  <strong>$1199</strong>
                  <span class="price">$899</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal5')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-lgreen">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVSix}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Book <br />
                    Trailer
                  </h3>
                  <p>
                    A book trailer is all about making the readers relate to the
                    story and suggesting various emotions that will allow the
                    reader to better understand what the book is all about. Book
                    trailers are a new concept of marketing the author’s book,
                    especially when they are designed in a professional way. The
                    book trailer’s ability to bring out the essence of the book
                    in a short and entertaining manner can help boost the author
                    sales.{' '}
                  </p>
                  <strong>$1249</strong>
                  <span class="price">$999</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal6')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
            </div>
            <div class="row marketing-slider">
              <div class="col-md-4 col-dorange">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVSeven}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Editorial
                    <br /> Critiques
                  </h3>
                  <p>
                    As an Author with a completed manuscript, would you like an
                    unbiased and professional opinion of your manuscript? Most
                    authors are always looking for someone else apart from
                    friends and family (who do sometimes offer a good opinion)
                    to read their manuscript and give a critique. This allows
                    you to gauge how the market will respond to your book by
                    getting literary experts to critique it.
                  </p>
                  <strong>$1199</strong>
                  <span class="price">$899</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal7')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-dgreen">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVEight}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Blogs for Book <br />
                    Promotion
                  </h3>
                  <p>
                    Bloggers are a really important part of the book world
                    today. The biggest fans of your book genre regularly
                    subscribe to or visit book review blogs dedicated to the
                    genre. The reach of the most influential book review blogs
                    in your genre is most likely in the tens of thousands. These
                    can be brilliant for getting nice reviews and spreading news
                    about a book at a grassroots level.
                  </p>
                  <strong>$1599</strong>
                  <span class="price">$1299</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal8')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-dblue">
                <div class="marketing-box">
                  <i>
                    <img
                      height="auto"
                      width="auto"
                      src={imagesSVNine}
                      title="img"
                      alt="image"
                    />
                  </i>
                  <h3 class="title">
                    Press <br />
                    Releases
                  </h3>
                  <p>
                    A press release announcing the launch of the book will be
                    distributed to hundreds of news agencies, media houses and
                    journalists. The release will include the cover image of the
                    book along with up to three links to e-commerce sites for
                    the reader to purchase if interested. A powerful press
                    release can take the author communication to new channels
                    &amp; reach new readers.{' '}
                  </p>
                  <strong>$899</strong>
                  <span class="price">$699</span>
                  <a
                    href="javascript:;"
                    class="get-btn"
                    onClick={openModalSection}
                  >
                    Get Started
                  </a>
                  <a
                    href="javascript:;"
                    type="button"
                    onClick={() => openServicesModal('mymodal9')}
                  >
                    View Sample
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="cta-1 cta-2">
          <div class="container-xl">
            <div class="cta-1-inner cta-2-inner">
              <h2>Get your book written By A</h2>
              <h4>team of professionals from start to finish!</h4>
              <p>
                Be one step closer to becoming the{' '}
                <span>BEST SELLING AUTHOR</span>
              </p>
              <a href="javascript:;" class="btn-1" onClick={openModalSection}>
                Let's Write Your Book
              </a>
              <a href="javascript:;" class="btn-1 chat">
                Lets Talk Right Away
              </a>
            </div>
          </div>
        </section>

        <section class="main-portfolio sec-pad">
          <div class="container intro-hdg">
            <p data-aos="fade-up" data-aos-duration="1500">
              OUR PORTFOLIO
            </p>
            <h2 data-aos="fade-up" data-aos-duration="1500">
              Have a Look at Our Master Pieces
            </h2>
            <div class="portfolio-tabs">
              <div class="row">
                <div class="col-lg-3">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#fiction"
                        role="tab"
                        aria-controls="fiction"
                      >
                        Fiction
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#non-fiction"
                        role="tab"
                        aria-controls="non-fiction"
                      >
                        History
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#biography"
                        role="tab"
                        aria-controls="biography"
                      >
                        Memoirs
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-toggle="tab"
                        href="#informative"
                        role="tab"
                        aria-controls="informative"
                      >
                        Thriller
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link last-child"
                        data-toggle="tab"
                        href="#autobiography"
                        role="tab"
                        aria-controls="autobiography"
                      >
                        Biographies
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-9">
                  <div class="tab-content">
                    <div class="tab-pane active" id="fiction" role="tabpanel">
                      <FictionSlider />
                    </div>
                    <div class="tab-pane" id="non-fiction" role="tabpanel">
                      <NonFictionSlider />
                    </div>
                    <div class="tab-pane" id="biography" role="tabpanel">
                      <BiographySlider />
                    </div>
                    <div class="tab-pane" id="informative" role="tabpanel">
                      <InformativeSlider />
                    </div>
                    <div class="tab-pane" id="autobiography" role="tabpanel">
                      <AutoBiographySlider />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="main-review sec-padd">
          <div class="container">
            <div
              class="review-text-wrap"
              data-aos="fade-down"
              data-aos-duration="1500"
            >
              <h2>Clients Testimonails</h2>
              <h3>Reviews from you</h3>
              <div
                id="carouselExampleIndicators"
                class="carousel slide "
                data-ride="carousel"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <div class="review-text">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                          <div class="review-box">
                            <h3>Julie Kirton</h3>
                            <div class="star">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                            </div>
                            <p>
                              {' '}
                              {WEBSITE_NAME} made my dream of becoming one of
                              the top-rated published author come true. They are
                              top professionals with a lot of creative ideas and
                              took care of everything from finalizing the plot
                              to designing and publishing. They revised the
                              draft a few times till I was 100% satisfied
                            </p>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="review-box">
                            <h3>Kenny Mills</h3>
                            <div class="star">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star-half-alt"></i>
                            </div>
                            <p>
                              It has been an amazing time working with{' '}
                              {WEBSITE_NAME}. Truly dedicated services and
                              uncanny approaches. My book got rated as the best
                              seller in the local market for a month-long time.
                              Thank you so much.
                            </p>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="review-box">
                            <h3>Trey Parker</h3>
                            <div class="star">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                            </div>
                            <p>
                              {WEBSITE_NAME} is a great ghostwriting company!
                              From the attitude of their professional sales
                              person to the way they adhere to their policies,
                              everything depicts their level of professionalism.
                              The content of my book sounds so engaging and
                              tends to entice the reader. I would surely be back
                              again.
                            </p>
                          </div>
                        </div>
                        <div class="carousel-item">
                          <div class="review-box">
                            <h3>Emma Tillmon</h3>
                            <div class="star">
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star-half-alt"></i>
                            </div>
                            <p>
                              Remarkable services! They dedicated their best
                              ghostwriter right at the moment when I placed my
                              order. She kept me well informed and I’m hugely
                              impressed by their level of professionalism. They
                              even helped me design the book cover which was
                              phenomenal! Kudos!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="review-img-wrap">
                      <ol class="carousel-indicators">
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="0"
                          class="active"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="1"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="2"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="3"
                        ></li>
                      </ol>
                    </div>
                  </div>
                </div>
                <a
                  class="carousel-control-prev cust-control-prev"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a
                  class="carousel-control-next cust-control-next"
                  href="#carouselExampleIndicators"
                  role="button"
                  data-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class="main-choose">
          <div class="container-fluid">
            <div class="heading" data-aos="fade-down" data-aos-duration="1500">
              <h2>Why Choose</h2>
              <h3> {WEBSITE_NAME}</h3>
              <p>
                The broad range of our services offered here will let you take a
                leap to success within days. Our work depicts remarkable
                expertise & lets you achieve a <br />
                prominent position among your target readers. To enhance our
                services & to keep our customers fully contented, we respect
                every feedback & bring
                <br /> possible changes to stand on their expectations.
              </p>
            </div>
            <div class="choose-wrap">
              <div class="row no-gutters choose-wrap-slider">
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Dedicated <br />
                      Ghostwriters
                    </h3>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="400"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Fast <br />
                      Delivery
                    </h3>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="600"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Creative <br />
                      Plots
                    </h3>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="800"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Non-Plagiarized <br />
                      Content
                    </h3>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="1000"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Unique Writing <br />
                      Styles
                    </h3>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="1200"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Fresh <br />
                      Content
                    </h3>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="1400"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Timely <br />
                      Updates
                    </h3>
                  </div>
                </div>
                <div class="col">
                  <div
                    class="choose-box"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    data-aos-delay="1600"
                  >
                    <img
                      height="auto"
                      width="auto"
                      src={chooseIcon}
                      title="img"
                      alt="img"
                      class="img-fluid"
                    />
                    <h3>
                      Unlimited <br />
                      Revisions
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="main-footer-form">
          <div class="container">
            <div class="heading" data-aos="fade-down" data-aos-duration="1500">
              <h2>Get In Touch</h2>
              <h3>
                With a Strategic <span>Consultant!</span>
              </h3>
              <p>
                We have worked with a number of businesses and individuals
                delivering prompt service
                <br /> with quality at its best. Fill out the details below and
                let us get back to you.
              </p>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="footer-form-wrap">
                  <form
                    class="form_submission"
                    method="POST"
                    onSubmit={onSubmitFunc}
                  >
                    <div class="row">
                      <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-group">
                          <span>
                            <i class="far fa-user"></i>
                          </span>
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            placeholder="Name"
                            required
                            onChange={(e) =>
                              setFormData({ ...formData, name: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-12 col-12">
                        <div class="form-group">
                          <span>
                            <i class="fas fa-phone-volume"></i>
                          </span>
                          <input
                            type="number"
                            name="phone"
                            class="form-control"
                            placeholder="Phone no."
                            minlength="10"
                            maxlength="12"
                            required
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                phone: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-sm-12 col-12">
                        <div class="form-group">
                          <span>
                            <i class="far fa-envelope-open"></i>
                          </span>
                          <input
                            type="text"
                            name="email"
                            class="form-control"
                            placeholder="Email Address"
                            required
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div class="col-lg-12 col-sm-12 col-12">
                        <div class="form-group">
                          <textarea
                            type="text"
                            name="message"
                            class="form-control"
                            placeholder="About Your Idea"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                message: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 col-sm-12 col-12">
                        <div class="btn-box">
                          {loading == true ? (
                            <div class="loader">
                              <img
                                height="auto"
                                width="auto"
                                title="img"
                                alt="loader"
                                src="loader.gif"
                              />
                            </div>
                          ) : (
                            <button type="submit" id="signupBtn">
                              Submit Now
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer class="main-footer">
        <div class="container">
          <div class="row justify-content-between ">
            <div class="col-xl-7 col-lg-5">
              <div class="footer-text">
                <img
                  height="auto"
                  width="auto"
                  src={logo}
                  title="img"
                  alt="img"
                  class="img-fluid"
                />
                <p>
                  {WEBSITE_NAME} are here to deliver instant help for any type
                  of Writing, Design and Marketing Service. We are a team of
                  highly creative, active and dedicated people.
                </p>
              </div>
            </div>
            <div class="col-xl-3 col-lg-5 pl-lg-0">
              <div class="footer-contact">
                <ul class="footer-address">
                  <li>
                    <a href={PHONE_HREF}>
                      <i class="fas fa-phone-volume"></i>
                      {PHONE}
                    </a>
                  </li>
                  <li>
                    <a href={EMAIL_HREF}>
                      <i class="fas fa-envelope-open"></i>
                      {EMAIL}
                    </a>
                  </li>
                </ul>
                <ul class="footer-social">
                  <li>
                    <a href="javascript:;">
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <i class="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;">
                      <img
                        height="auto"
                        width="auto"
                        src={trust}
                        title="img"
                        alt="img"
                        class="img-fluid"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="main-copyright">
          <div class="container">
            <div class="row justify-content-around align-items-center">
              <div class="col-lg">
                <div class="footer-para">
                  <p>Copyright © 2024 {WEBSITE_NAME}. All rights reserved.</p>
                </div>
              </div>
              <div class="col-lg">
                <div class="footer-links">
                  <ul>
                    <li>
                      <a href="../terms.php">Terms &amp; Conditions</a>
                    </li>
                    <li>
                      <a href="../privacy.php">Privay Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <ModalSection
        open={open}
        onCloseModal={onCloseModal}
        id={modalConfig.id}
        modalTitle={modalConfig.title}
        modalContent={modalConfig.content}
        modalImage={modalConfig.image}
        iconPath={modalConfig.icon}
      /> */}
      <ModalSection
        open={isModalSectionOpen}
        onCloseModal={closeModalSection}
      />
      {/* <ServicesModal open={isServicesModalOpen} onCloseModal={closeServicesModal} /> */}
      <ServicesModal
        open={modalState.servicesModal !== false}
        onCloseModal={closeServicesModal}
        id={modalState.servicesModal}
      />
    </div>
  );
}

export default App;
