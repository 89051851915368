import React from 'react';
import OwlCarousel from 'react-owl-carousel';

import {
    nonFictionFirst,
    nonFictionSecond,
    nonFictionThird,
    nonFictionFourth,
    nonFictionFive,
    nonFictionSixth,
} from '../assets/images';


const BiographySlider = () => {
  return (
    <OwlCarousel className='work-portfolio owl-carousel owl-theme show-nav-title custom-dots-style-1 custom-dots-position custom-xs-arrows-style-2' loop margin={10}>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={nonFictionFirst    } title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={nonFictionSecond   } title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={nonFictionThird    } title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={nonFictionFourth   } title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={nonFictionFive } title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={nonFictionSixth    } title="img" alt="img"/>
            </div>
        </div>
    </OwlCarousel>
  );
}

export default BiographySlider;