const Admin_Panel = 'info@auhtorspublishinglab.com';
const PHONE = '(855) 556-3532';
const PHONE_HREF = 'tel:8555563532';
// const PHONE_TWO = '+61 489 951 988';
// const PHONE_TWO_HREF = 'tel:61489951988';
const EMAIL = 'info@auhtorspublishinglab.com';
const EMAIL_HREF = 'mailto:info@auhtorspublishinglab.com';
const CONTACT_EMAIL = 'info@auhtorspublishinglab.com';
const CONTACT_EMAIL_HREF = 'mailto:info@auhtorspublishinglab.com';
const ADDRESS = '465 Pine St, San Francisco, CA 94104, USA';
const WEBSITE_NAME = 'Authors Publishing Lab';
const SITE_CURRENCY_SYMBOLS = '$';
const WEBSITE_LOGO = 'https://www.auhtorspublishinglab.com/logo.png';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  //   PHONE_TWO,
  //   PHONE_TWO_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
  WEBSITE_LOGO,
};
