import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { logoGif } from '../../assets/images';

export const ModalSection = ({ open, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "authorspublishinglab.com"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=135688';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://authorspublishinglab.com/thank-you?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  return (
    <Modal show={open} onHide={onCloseModal} aria-labelledby="contained-modal-title-vcenter" id="popup_form" centered>
        <div class="modal-dialog modal-dialog-centered">
            <div class="entry-box">
                <div class="row no-gutters justify-content-center h-100 align-items-center">
                    <div class="col-lg-7 col-md-8 popup-form-wrap">
                        <div class="popup-form-img">
                            <button type="button" class="close" onClick={onCloseModal}>×</button>
                        </div>
                        <h2>Launch <br/><span>Your Book</span></h2>
                        <div class="timmer_content">
                            <ul class="flip-clock-container flip-2">
                                <li class="flip-item-seconds">00</li>
                                <li class="flip-item-minutes">42</li>
                                <li class="flip-item-hours">1</li>
                            </ul>
                        </div>
                        <div class="discount-label">
                            <h3>50% <span>Discount</span></h3>
                        </div>
                        <p>Get Your Name In The List Of <span>Best-Selling Authors</span> </p>
                        <div class="form-box-wrap">
                            <form class="popup_form_wrap form_submission" method="POST" onSubmit={onSubmitFunc}>
                                <div class="input-group no-gutters">
                                        <div class=" col-md-4">
                                            <input class="form-control" name="name" placeholder="Enter your name"
                                                type="text" required onChange={(e) =>setFormData({ ...formData, name: e.target.value })}/>
                                        </div>
                                        <div class=" col-md-4">
                                            <input class="form-control" name="email" placeholder="Enter your Email"
                                                type="text" required onChange={(e) =>setFormData({ ...formData, email: e.target.value,})}/>
                                        </div>
                                        <div class=" col-md-4">
                                            <input class="form-control" name="phone" placeholder="Enter your phone no"
                                                type="number" minlength="10" maxlength="12" required onChange={(e) =>setFormData({...formData,phone: e.target.value,})}/>
                                        </div>
                                    </div>
                                    <div class="form_btn_wrap col-md-12">
                                        {loading == true ? (
                                            <div class="loader">
                                                <img height="auto" width="auto" title="img" alt="loader" src="loader.gif"/>
                                            </div>
                                        ) : (
                                          <button class="learn-more-tab" type="submit">Submit</button>
                                        )}                                
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
  );
};