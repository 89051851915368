import React from 'react';
import OwlCarousel from 'react-owl-carousel';

import {
    autobiographyFirst,
    autobiographySecond,
    autobiographyThird,
    autobiographyFourth,
    autobiographyFive,
    autobiographySixth,
} from '../assets/images';


const AutoBiographySlider = () => {
  return (
    <OwlCarousel className='work-portfolio owl-carousel owl-theme show-nav-title custom-dots-style-1 custom-dots-position custom-xs-arrows-style-2' loop margin={10}>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={autobiographyFirst} title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={autobiographySecond} title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={autobiographyThird} title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={autobiographyFourth} title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={autobiographyFive} title="img" alt="img"/>
            </div>
        </div>
        <div class="item">
            <div class="img_div">
                <img height="auto" width="auto" src={autobiographySixth} title="img" alt="img"/>
            </div>
        </div>
    </OwlCarousel>
  );
}

export default AutoBiographySlider;