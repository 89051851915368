import React, { useState } from 'react';
import axios from 'axios';

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '',
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const nextPrev = (n) => {
    if (n === 1) {
      // Moving forward
      if (step === 1 && formData.name === '') {
        setStep(2);
      } else if (step === 2 && formData.email === '') {
        setStep(3);
      } else if (step === 3 && formData.phone === '') {
        setStep(4);
      } else if (step === 4 && formData.message !== '') {
        submitForm();
      } else if (step < 4) {
        setStep(step + n);
      }
    } else {
      // Moving backward
      if (step > 1) {
        setStep(step + n);
      }
    }
  };

  const submitForm = async () => {
    // No event parameter here, so we don't call preventDefault
    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;

      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=562174';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status === 200) {
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          window.location.href = `https://authorspublishinglab.com/thank-you?${queryString}`;
        }
        console.log('response', response);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    }
  };

  return (
    <div className="side-form">
      <form className="sign_up_form form_submission" id="regForm" method="POST">
        <div className="row no-gutters justify-content-center align-items-center banner-frm-row">
          <div className="col-md-6 col-lg-6">
            {step === 1 && (
              <div className="form-group side-form-group tab name-field name-field-1">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter Your Name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </div>
            )}
            {step === 2 && (
              <div className="form-group side-form-group tab name-field name-field-2">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Your E-mail"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
            )}
            {step === 3 && (
              <div className="form-group side-form-group tab name-field name-field-3">
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  placeholder="Phone no"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                />
              </div>
            )}
            {step === 4 && (
              <div className="form-group side-form-group tab name-field name-field-4">
                <input
                  type="text"
                  className="form-control"
                  name="message"
                  placeholder="Your Message"
                  value={formData.message}
                  onChange={(e) =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 text-center">
            <div className="cta-form-btn d-flex">
              {step > 1 && (
                <button
                  className="btn-1"
                  type="button"
                  onClick={() => nextPrev(-1)}
                >
                  Previous
                </button>
              )}
              {step < 4 && (
                <button
                  className="btn-1"
                  type="button"
                  onClick={() => nextPrev(1)}
                >
                  Next
                </button>
              )}
              {step === 4 && (
                <button
                  type="button"
                  className="learn-more-tab btn-1"
                  onClick={() => nextPrev(1)}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </div>
        {loading && <p>Loading...</p>}
        {errors && <p>Please check your inputs</p>}
      </form>
    </div>
  );
};

export default MultiStepForm;
